@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
    :root{
        --color-primary: 77, 171, 110;
        --color-primary-hover: 56, 135, 84;
        --color-primary-disabled: 131, 167, 144;
    
    }
    html {
        font-family: "Inter var", system-ui, sans-serif;
      }
}
